import { FunctionComponent, MouseEventHandler, useCallback } from 'react';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Td, Tr } from '@brainstud/ui/Static/Table';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import classNames from 'classnames/bind';
import styles from './CollapsibleRow.module.css';

const cx = classNames.bind(styles);

type TProps<T> = {
  isExpanded: boolean;
  isSelected: boolean;
  data: T;
  onToggle: (id: string) => void;
  onToggleSelect: (selectedIds: string[]) => void;
  rowComponent: FunctionComponent<TRowProps<T>>;
  expandedRowComponent: FunctionComponent<{ data: T }>;
};

type TRowProps<T> = {
  isExpanded: boolean;
  isSelected: boolean;
  data: T;
  onToggleSelect: (selectedIds: string[]) => void;
};

interface IData extends Object {
  id: string;
}

/**
 * CollapsibleRow.
 *
 * Pass in a row component to dictate how to render a row.
 * Pass in an expanded row component to dictate how to render the corresponding
 * row(s) beneath the row component.
 */
export const CollapsibleRow = <T extends IData>({
  data,
  onToggle,
  onToggleSelect,
  isExpanded,
  rowComponent: RowComponent,
  expandedRowComponent: ExpandedRowComponent,
  isSelected,
}: TProps<T>) => {
  const handleToggle = useCallback<MouseEventHandler<HTMLTableRowElement>>(
    (e) => {
      const targetBoundingBox = e.currentTarget.getBoundingClientRect();
      const header = document.querySelector('header')?.getBoundingClientRect();

      if (targetBoundingBox) {
        window.scrollTo({
          behavior: 'smooth',
          top: targetBoundingBox.top + window.scrollY - (header?.height || 0),
        });
      }
      onToggle(data.id);
    },
    [onToggle, data.id]
  );

  return (
    <>
      <Tr key={data.id} className={cx({ isExpanded })} onClick={handleToggle}>
        <RowComponent
          data={data}
          isSelected={isSelected}
          isExpanded={isExpanded}
          onToggleSelect={onToggleSelect}
        />

        <Td right collapse className={cx(styles.expand)}>
          <Button type="button" small quiet className={cx(styles.button)}>
            {isExpanded ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </Button>
        </Td>
      </Tr>

      {isExpanded && <ExpandedRowComponent data={data} />}
    </>
  );
};
