/* eslint-disable react/jsx-props-no-spreading */
import React, {
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
} from 'react';
import classNames from 'classnames/bind';
import { Button } from '../../../Buttons/Button';
import styles from './TrThTd.module.css';

const classes = classNames.bind(styles);

interface Props extends React.ComponentProps<'td'> {
  /** Cell that contains the main data point, which receives extra styling */
  main?: boolean;
  /** An anchor tag to an internal route */
  to?: string;
  /** An anchor tag to an external route */
  href?: string;
  /** Sets the target of the URL, e.g. _blank */
  target?: string;
  center?: boolean;
  /** Collapse the width of the column to its content */
  collapse?: boolean;
  right?: boolean;
  /** Prevent click propagation to row for this specific cell */
  stopPropagation?: boolean;
  /** Wrap content */
  wraps?: boolean;
}

/**
 * Use the td (table data) cell component within the Table component. It can help with the styling of clickable cells.
 */
export const Td = ({
  to,
  href,
  target,
  onClick,
  stopPropagation,
  center,
  right,
  collapse,
  main,
  wraps,
  className,
  style,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  const handleClick = useCallback<MouseEventHandler<HTMLTableCellElement>>(
    (event) => {
      if (onClick || stopPropagation) {
        event.stopPropagation();
        return onClick?.(event);
      }
      return true;
    },
    [onClick, stopPropagation]
  );

  return onClick || href || to ? (
    <td
      className={classes(
        styles.base,
        styles.main,
        {
          center,
          right,
          collapse,
          wraps,
        },
        'ui-td__base',
        'ui-th__main',
        className
      )}
      style={style}
      {...props}
    >
      <Button
        type="button"
        quiet
        to={to}
        href={href}
        target={target}
        onClick={handleClick}
        className={classes(styles.button, 'ui-td__button')}
      >
        {children}
      </Button>
    </td>
  ) : (
    <td
      className={classes(
        styles.base,
        {
          main,
          center,
          right,
          collapse,
          wraps,
        },
        'ui-th__base',
        className
      )}
      style={style}
      {...props}
    >
      {children}
    </td>
  );
};
